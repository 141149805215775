import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = {
  class: "col",
  id: "errorsTitle"
}
const _hoisted_4 = { class: "row pl-0" }
const _hoisted_5 = { class: "col pl-0" }
const _hoisted_6 = ["innerHTML"]

import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputValidationErrors',
  props: {
  errorMessages: {
    type: Array as PropType<Array<string>>,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(props.title)), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.errorMessages, (message) => {
            return (_openBlock(), _createElementBlock("li", {
              key: message,
              innerHTML: message,
              class: "errorMessage"
            }, null, 8, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})