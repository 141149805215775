<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.validateEmailTitle") }}</h2>
            </div>
          </div>
          <div class="row justify-content-between mb-4">
            <div class="col-auto">
              <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
              <span class="actionableLabel" @click="goToLogin()">{{
                $t("login.name")
              }}</span>
            </div>
            <div class="col-auto">
              <span
                class="actionableLabel"
                v-if="validateEmailType === 'validate'"
                @click="validateEmailType = 'resend'"
                >{{ $t("login.resendValidateEmail") }}</span
              >
              <span
                v-else
                class="actionableLabel"
                @click="validateEmailType = 'validate'"
                >{{ $t("login.haveACodeYet") }}</span
              >
            </div>
          </div>
          <div class="row mb-4" v-if="validateEmailType === 'validate'">
            <div class="col-12">
              <InputMaster
                placeholder="login.email"
                inputName="validateEmailEmail"
                v-model="validationData.email"
                :rules="[emailRule()]"
              ></InputMaster>
            </div>
          </div>
          <div class="row mb-4" v-if="validateEmailType === 'validate'">
            <div class="col-12">
              <InputMaster
                placeholder="login.confirmationCode"
                inputName="validateEmailCode"
                v-model="validationData.confirmationCode"
                :rules="[onlyNumberCharacters(), minQuantity(3)]"
              ></InputMaster>
            </div>
          </div>

          <div class="row mb-4" v-if="validateEmailType === 'resend'">
            <div class="col-12">
              <InputMaster
                placeholder="login.email"
                inputName="resendValidateEmailEmail"
                v-model="resendValidationData.email"
                :rules="[emailRule()]"
              ></InputMaster>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <button class="btn w-100" id="btnLogin" @click="submitValidation">
                {{
                  validateEmailType === "resend"
                    ? $t("login.request")
                    : $t("login.viewsSubmit")
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import {
  emailRule,
  minQuantity,
  onlyNumberCharacters,
} from "@/shared/globals/inputs/const/RuleInput.const";
import { ResendValidateEmailDto } from "@/store/auth/dtos/ResendValidateEmail.dto";
import { ValidateEmailDto } from "@/store/auth/dtos/ValidateEmail.dto";
import { defineEmits, ref, watch } from "vue";
import InputMaster from "../globals/inputs/InputMaster.vue";

const { validateEmail, resendValidateEmail, validateEmailType } =
  useAuthServices();

const emit = defineEmits(["goToLogin"]);

const validationData = ref<ValidateEmailDto>({
  confirmationCode: "",
  email: "",
});
const resendValidationData = ref<ResendValidateEmailDto>({
  email: "",
});

function goToLogin(): void {
  emit("goToLogin");
}

function submitValidation(): void {
  if (validateEmailType.value === "validate") {
    validateEmail(validationData.value, goToLogin);
  } else {
    resendValidateEmail(resendValidationData.value);
  }
}

watch(validateEmailType, (newValue) => {
  if (newValue === "validate") {
    validationData.value.email = resendValidationData.value.email;
  } else {
    resendValidationData.value.email = validationData.value.email;
  }
});
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnLogin {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
