<template>
  <div>
    <label v-if="props.labelText" :for="props.inputName" class="form-label">{{
      $t(props.labelText)
    }}</label>
    <div class="input-group has-validation">
      <span
        v-if="props.startLabel"
        class="input-group-text"
        :id="`${props.inputName}GroupPrepend`"
        >{{ props.startLabel }}</span
      >
      <input
        v-model="inputValue"
        :type="props.type"
        :class="`form-control ${
          !rules ? '' : isValid ? 'is-valid' : 'is-invalid'
        }`"
        :id="props.inputName"
        :aria-describedby="`${props.inputName}GroupPrepend ${props.inputName}Feedback`"
        :placeholder="props.placeholder ? $t(props.placeholder) : ''"
      />
      <!-- <span class="input-group-append" v-if="props.iconInside">
        <b-icon
          v-if="props.iconInside"
          :icon="props.iconInside.name"
          class="iconInsideInput"
          :color="props.iconInside.color"
      /></span> -->
      <span
        v-if="props.endLabel"
        class="input-group-text"
        :id="`${props.inputName}GroupPrepend`"
        >{{ props.endLabel }}</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTooltip } from "@/composables/useTooltipServices";
import { RuleInput } from "@/shared/globals/inputs/types/RuleInput.type";
import {
  computed,
  defineModel,
  defineProps,
  onMounted,
  PropType,
  watch,
} from "vue";
import InputValidationErrors from "../errors/InputValidationErrors.vue";

const { display, dispose } = useTooltip();

const props = defineProps({
  labelText: {
    type: String,
    required: false,
  },
  inputName: {
    type: String,
    required: true,
  },
  rules: {
    type: Array as PropType<Array<RuleInput>>,
    required: false,
  },
  type: {
    type: String,
    default: "text",
  },
  startLabel: {
    type: String,
    required: false,
    default: "",
  },
  endLabel: {
    type: String,
    required: false,
    default: "",
  },
  placeholder: {
    type: String,
    required: false,
  },
  iconInside: {
    type: Object as PropType<{ name: string; color: string }>,
    required: false,
  },
});

const inputValue = defineModel();

const isValid = computed(() => {
  return props.rules && errorMessages.value.length === 0;
});

const errorMessages = computed(() => {
  const messages: Array<string> = [];
  props.rules?.forEach((rule) => {
    if (!inputValue.value?.toString().match(rule.regExp)) {
      messages.push(rule.messageI18n);
    }
  });
  return messages;
});

function loadTooltip() {
  display({
    querySelector: `#${props.inputName}`,
    placement: "auto",
    childComponent: InputValidationErrors,
    childProps: {
      errorMessages: errorMessages.value,
      title: "global.inputs.ruleName",
    },
    trigger: "hover",
  });
}

watch(isValid, () => {
  if (!isValid.value) {
    loadTooltip();
  } else {
    dispose();
  }
});

onMounted(() => {
  // if (!isValid.value) {
  //   loadTooltip();
  // } else {
  //   dispose();
  // }
});
</script>

<style lang="scss" scoped>
.iconInsideInput {
  position: relative;
  left: -1.5em;
  top: 0.6em;
}
</style>
