import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = {
  class: "container",
  id: "formBox"
}
const _hoisted_5 = { class: "row justify-content-center mb-3" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "textBlack" }
const _hoisted_8 = { class: "row justify-content-between mb-4" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = {
  key: 1,
  class: "row mb-4"
}
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = {
  key: 2,
  class: "row mb-4"
}
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "row mb-3" }
const _hoisted_18 = { class: "col-12" }

import { useAuthServices } from "@/composables/useAuthServices";
import {
  emailRule,
  minQuantity,
  onlyNumberCharacters,
} from "@/shared/globals/inputs/const/RuleInput.const";
import { ResendValidateEmailDto } from "@/store/auth/dtos/ResendValidateEmail.dto";
import { ValidateEmailDto } from "@/store/auth/dtos/ValidateEmail.dto";
import { ref, watch } from "vue";
import InputMaster from "../globals/inputs/InputMaster.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ValidateEmail',
  emits: ["goToLogin"],
  setup(__props, { emit: __emit }) {

const { validateEmail, resendValidateEmail, validateEmailType } =
  useAuthServices();

const emit = __emit;

const validationData = ref<ValidateEmailDto>({
  confirmationCode: "",
  email: "",
});
const resendValidationData = ref<ResendValidateEmailDto>({
  email: "",
});

function goToLogin(): void {
  emit("goToLogin");
}

function submitValidation(): void {
  if (validateEmailType.value === "validate") {
    validateEmail(validationData.value, goToLogin);
  } else {
    resendValidateEmail(resendValidationData.value);
  }
}

watch(validateEmailType, (newValue) => {
  if (newValue === "validate") {
    validationData.value.email = resendValidationData.value.email;
  } else {
    resendValidationData.value.email = validationData.value.email;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("login.validateEmailTitle")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
              _createElementVNode("span", {
                class: "actionableLabel",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goToLogin()))
              }, _toDisplayString(_ctx.$t("login.name")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_unref(validateEmailType) === 'validate')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "actionableLabel",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (validateEmailType.value = 'resend'))
                  }, _toDisplayString(_ctx.$t("login.resendValidateEmail")), 1))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: "actionableLabel",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (validateEmailType.value = 'validate'))
                  }, _toDisplayString(_ctx.$t("login.haveACodeYet")), 1))
            ])
          ]),
          (_unref(validateEmailType) === 'validate')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(InputMaster, {
                    placeholder: "login.email",
                    inputName: "validateEmailEmail",
                    modelValue: validationData.value.email,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((validationData.value.email) = $event)),
                    rules: [_unref(emailRule)()]
                  }, null, 8, ["modelValue", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(validateEmailType) === 'validate')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(InputMaster, {
                    placeholder: "login.confirmationCode",
                    inputName: "validateEmailCode",
                    modelValue: validationData.value.confirmationCode,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((validationData.value.confirmationCode) = $event)),
                    rules: [_unref(onlyNumberCharacters)(), _unref(minQuantity)(3)]
                  }, null, 8, ["modelValue", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(validateEmailType) === 'resend')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(InputMaster, {
                    placeholder: "login.email",
                    inputName: "resendValidateEmailEmail",
                    modelValue: resendValidationData.value.email,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((resendValidationData.value.email) = $event)),
                    rules: [_unref(emailRule)()]
                  }, null, 8, ["modelValue", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                class: "btn w-100",
                id: "btnLogin",
                onClick: submitValidation
              }, _toDisplayString(_unref(validateEmailType) === "resend"
                    ? _ctx.$t("login.request")
                    : _ctx.$t("login.viewsSubmit")), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})