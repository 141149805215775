import { DisplayPayload } from "@/shared/globals/tooltips/types/DisplayPayload.type";
import i18n from "@/shared/locales/i18n";
import { Tooltip } from "bootstrap";
import { Component, createApp, h, ref, Ref } from "vue";
import PopoverDynamic from "../components/globals/popovers/PopoverDynamic.vue";

export function useTooltip() {
  const refChildComponent: Ref<Component> = ref({});
  const refChildComponentProps: Ref<{ [k in string]: unknown }> = ref({});
  const refTooltip: Ref<Tooltip | null> = ref(null);
  const refElementTarget: Ref<Element | null> = ref(null);
  const refCustomClass: Ref<string | undefined> = ref();

  function mountAndAppend() {
    const tooltipContent = document
      .getElementsByClassName("tooltip-inner")
      .item(0);

    tooltipContent?.classList.add("basicBodyTooltip");
    const element = createApp({
      render() {
        return h(PopoverDynamic, null, {
          contentPopover: () =>
            h(refChildComponent.value, refChildComponentProps.value),
          default: () =>
            h("p", "This popover content was created programmatically."),
        });
      },
    });
    element.use(i18n).mount(tooltipContent as Element);
    refTooltip.value?.hide();
  }

  function display(payload: DisplayPayload) {
    const {
      querySelector,
      placement,
      childComponent,
      childProps,
      trigger,
      customClass,
    } = payload;

    refCustomClass.value = customClass;

    refChildComponent.value = childComponent;
    if (childProps) {
      refChildComponentProps.value = childProps;
    }
    refElementTarget.value = document.querySelectorAll(querySelector).item(0);
    refElementTarget.value.setAttribute("data-bs-toggle", "tooltip");
    refElementTarget.value.setAttribute("data-bs-placement", placement);
    refElementTarget.value.setAttribute("data-bs-title", " ");

    refTooltip.value = new Tooltip(document.body, {
      trigger,
      placement,
      selector: querySelector,
    });
    refTooltip.value.show();

    refElementTarget.value.addEventListener("shown.bs.tooltip", mountAndAppend);
  }

  function dispose() {
    refTooltip.value?.hide();
    refTooltip.value?.dispose();
  }

  return { display, dispose };
}
