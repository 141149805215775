import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-group has-validation" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["type", "id", "aria-describedby", "placeholder"]
const _hoisted_5 = ["id"]

import { useTooltip } from "@/composables/useTooltipServices";
import { RuleInput } from "@/shared/globals/inputs/types/RuleInput.type";
import {
  computed,
  onMounted,
  PropType,
  watch,
} from "vue";
import InputValidationErrors from "../errors/InputValidationErrors.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMaster',
  props: /*@__PURE__*/_mergeModels({
  labelText: {
    type: String,
    required: false,
  },
  inputName: {
    type: String,
    required: true,
  },
  rules: {
    type: Array as PropType<Array<RuleInput>>,
    required: false,
  },
  type: {
    type: String,
    default: "text",
  },
  startLabel: {
    type: String,
    required: false,
    default: "",
  },
  endLabel: {
    type: String,
    required: false,
    default: "",
  },
  placeholder: {
    type: String,
    required: false,
  },
  iconInside: {
    type: Object as PropType<{ name: string; color: string }>,
    required: false,
  },
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

const { display, dispose } = useTooltip();

const props = __props;

const inputValue = _useModel(__props, "modelValue");

const isValid = computed(() => {
  return props.rules && errorMessages.value.length === 0;
});

const errorMessages = computed(() => {
  const messages: Array<string> = [];
  props.rules?.forEach((rule) => {
    if (!inputValue.value?.toString().match(rule.regExp)) {
      messages.push(rule.messageI18n);
    }
  });
  return messages;
});

function loadTooltip() {
  display({
    querySelector: `#${props.inputName}`,
    placement: "auto",
    childComponent: InputValidationErrors,
    childProps: {
      errorMessages: errorMessages.value,
      title: "global.inputs.ruleName",
    },
    trigger: "hover",
  });
}

watch(isValid, () => {
  if (!isValid.value) {
    loadTooltip();
  } else {
    dispose();
  }
});

onMounted(() => {
  // if (!isValid.value) {
  //   loadTooltip();
  // } else {
  //   dispose();
  // }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.labelText)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: props.inputName,
          class: "form-label"
        }, _toDisplayString(_ctx.$t(props.labelText)), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (props.startLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "input-group-text",
            id: `${props.inputName}GroupPrepend`
          }, _toDisplayString(props.startLabel), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        type: props.type,
        class: _normalizeClass(`form-control ${
          !__props.rules ? '' : isValid.value ? 'is-valid' : 'is-invalid'
        }`),
        id: props.inputName,
        "aria-describedby": `${props.inputName}GroupPrepend ${props.inputName}Feedback`,
        placeholder: props.placeholder ? _ctx.$t(props.placeholder) : ''
      }, null, 10, _hoisted_4), [
        [_vModelDynamic, inputValue.value]
      ]),
      (props.endLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "input-group-text",
            id: `${props.inputName}GroupPrepend`
          }, _toDisplayString(props.endLabel), 9, _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})