<template>
  <div class="container-fluid">
    <div class="row" v-if="props.title">
      <div class="col" id="errorsTitle">
        {{ $t(props.title) }}
      </div>
    </div>
    <div class="row pl-0">
      <div class="col pl-0">
        <ul>
          <li
            v-for="message in props.errorMessages"
            :key="message"
            v-html="message"
            class="errorMessage"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, defineProps } from "vue";

const props = defineProps({
  errorMessages: {
    type: Array as PropType<Array<string>>,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
});
</script>

<style lang="scss" scoped>
.errorMessage {
  text-align: justify;
  text-justify: inter-word;
}
.errorsTitle {
  text-justify: center;
}
</style>
