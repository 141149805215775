<template>
  <GeneralCard>
    <template v-slot:content>
      <div class="container-fluid p-3">
        <div class="row justify-content-center">
          <div class="col-auto mb-2">
            <ProfilePicture
              :image="props.userData.profilePic"
              :name="name"
              width="2.5em"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <p class="m-0 fs-09em">
              {{ name }}
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <p class="m-0 fs-07em textPale">
              {{ props.userData.position }}
            </p>
          </div>
        </div>

        <div class="row mt-2 justify-content-center actionsContainer">
          <div class="col-auto pr-1">
            <button class="btn p-0 bgPale">
              <i class="bi bi-pen fs-07em"></i>
            </button>
          </div>
          <div class="col-auto pl-1">
            <button class="btn p-0 bgPale">
              <i class="bi bi-trash fs-07em"></i>
            </button>
          </div>
        </div>
        <hr />
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <p class="textPale m-0">
                    {{ props.userData.employeeId }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="textPale m-0">{{ $t("global.employee") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  {{ formatNumberCurrency(props.userData.amount) }}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="textPale m-0">{{ $t("global.earnings") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
  </GeneralCard>
</template>

<script setup lang="ts">
import { formatNumberCurrency } from "@/shared/globals/helpers/Number.helper";
import { UserTagExtended } from "@/shared/globals/tags/types/UserTag.type";
import { PropType, computed, defineProps } from "vue";
import ProfilePicture from "../profile/ProfilePicture.vue";
import GeneralCard from "./GeneralCard.vue";

const props = defineProps({
  userData: {
    type: Object as PropType<UserTagExtended>,
  },
});

const name = computed(() => {
  return `${props.userData.firstName} ${props.userData.lastName}`;
});

console.log(props.userData);
</script>

<style lang="scss" scoped>
.actionsContainer {
  button {
    height: 30px;
    width: 30px;
  }
}
</style>
