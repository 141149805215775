import i18n from "@/shared/locales/i18n";
import { StatusesBadgesType } from "../types/StatusesBadges.types";

export const StatusesBadges = (): StatusesBadgesType[] => {
  return [
    {
      key: "active",
      label: i18n.global.t("global.statuses.active"),
      bgColor: "#ecfae5",
      color: "#7ab97b",
    },
    {
      key: "deny",
      label: i18n.global.t("global.statuses.deny"),
      bgColor: "#fee4ef",
      color: "#f17190",
    },
    {
      key: "pending",
      label: i18n.global.t("global.statuses.pending"),
      bgColor: "#fff2db",
      color: "#f5c46a",
    },
    {
      key: "new",
      label: i18n.global.t("global.statuses.new"),
      bgColor: "#eff4fa",
      color: "#b0b7bf",
    },
    {
      key: "inactive",
      label: i18n.global.t("global.statuses.inactive"),
      bgColor: "#eff4fa",
      color: "#b0b7bf",
    },
  ];
};
