import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { User } from "@/store/auth/models/User.model";
import { GetUsers } from "@/store/users/services/Users.service";
import { computed, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastService";

export function useUsersServices() {
  const store = useStore();
  const { t } = useI18n();
  const user = computed(() => {
    return store.state.auth.user;
  });

  const currentUsers: Ref<User[]> = ref([]);

  function getUsers(tablePayload: GetTableDataDto) {
    GetUsers(tablePayload)
      .then(({ data }) => {
        currentUsers.value = data;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
      });
  }

  return { user, getUsers, currentUsers };
}
