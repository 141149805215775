<template>
  <IsLoading v-if="isLoading" />
  <div v-else class="container-fluid tableParentContainer">
    <div class="row" v-if="props.label">
      <div class="col-auto mt-3 mb-3">
        <span class="tableLabel">{{ props.label }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col tableContainer">
        <table class="w-100">
          <colgroup>
            <col
              span="1"
              :style="`width: ${header.width}`"
              v-for="header in translatedHeaders"
              :key="`tableCol${props.name}${header.key}`"
            />
          </colgroup>
          <tbody>
            <tr>
              <th
                class="tableHeader pt-2 pb-2 border textPale"
                v-for="header in translatedHeaders"
                :style="`width: ${header.width}`"
                :key="`tableHeader${props.name}${header.key}`"
              >
                {{ header.value }}
              </th>
            </tr>
            <tr
              v-for="(value, index) in mappedData"
              :key="`tableRow${props.name}${index}`"
              class="tableRow"
            >
              <td
                v-for="(data, dataIndex) in getTableRow(value)"
                :key="`tableCell${props.name}${index}-${dataIndex}-${data}`"
              >
                <p class="m-0" v-if="data.type === 'string'">
                  {{ data.value }}
                </p>
                <p class="m-0" v-else-if="data.type === 'currency'">
                  {{ formatNumberCurrency(data.value as number) }}
                </p>
                <StatusBadge
                  v-else-if="data.type === 'catalogStatus'"
                  :status="(data.value as StatusKeyType)"
                />
                <ProfileTag
                  v-else-if="data.type === 'userTag'"
                  :userData="(data.value as UserTag)"
                  :showEmail="data.metadata.showEmail"
                />

                <DropdownMenu
                  v-else-if="data.type === 'action'"
                  :menuData="(data.value as Menu)"
                  :name="`menu-${index}-${dataIndex}`"
                />

                <p class="m-0" v-else>{{ data.value }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row justify-content-between mt-2">
      <div class="col-auto d-flex align-items-center">
        <div class="container-fluid">
          <div class="row">
            <div class="col-auto textPale">
              {{ $t("global.tables.resultsPerPage") }}
            </div>
            <div class="col-auto">
              <div class="dropdown">
                <button
                  class="badge rounded-pill numberResultsBadge"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ pageSize }} <i class="bi bi-caret-down"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-auto">
                  <li
                    v-for="(
                      resultsPerPageOption, index
                    ) in resultsPerPageOptions"
                    :key="`${props.name}-${index}-optionPaginator-${resultsPerPageOption}`"
                    @click="pageSize = resultsPerPageOption"
                  >
                    <a class="dropdown-item" href="#" value="">{{
                      resultsPerPageOption
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-auto">
        <nav aria-label="navigationTable" class="p-2">
          <ul class="pagination m-0">
            <li
              v-if="currentPage > totalPages"
              class="page-item"
              @click="prevPage()"
            >
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li
              class="page-item"
              v-for="page of pagesList"
              :key="`${name}-paginator-${page}`"
              @click="currentPage = page"
            >
              <a
                :class="`page-link ${currentPage == page ? 'active' : ''}`"
                href="#"
                >{{ page }}</a
              >
            </li>
            <li
              v-if="currentPage < totalPages"
              class="page-item"
              @click="nextPage()"
            >
              <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTablesServices } from "@/composables/useTablesServices";
import { DateHelper, DateInput } from "@/shared/dates/helpers/Date.helper";
import { CatalogStatus } from "@/shared/globals/badges/types/CatalogStatus.type";
import { StatusKeyType } from "@/shared/globals/badges/types/StatusesBadges.types";
import { formatNumberCurrency } from "@/shared/globals/helpers/Number.helper";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { headers } from "@/shared/globals/tables/consts/Headers.const";
import { ResultsPerPageOptions } from "@/shared/globals/tables/consts/ResultsPerPage.const";
import { HeaderMetadata } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { ColumnType } from "@/shared/globals/tables/types/HeaderTypes.type";
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import { range } from "lodash";
import {
  ComputedRef,
  PropType,
  Ref,
  computed,
  defineProps,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import DropdownMenu from "../UiTools/DropdownMenu.vue";
import IsLoading from "../UiTools/IsLoading.vue";
import StatusBadge from "../UiTools/StatusBadge.vue";
import ProfileTag from "../profile/ProfileTag.vue";

const store = useStore();
const props = defineProps({
  headersName: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  dataSource: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    default: "id",
  },
  orderType: {
    type: String as PropType<"asc" | "desc">,
    default: "desc",
  },
});

const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  translatedHeaders,
  loadingTable,
} = useTablesServices(
  props.dataSource,
  headers[props.headersName],
  props.orderBy,
  props.orderType
);

const formatDate = computed(() => {
  return store.state.auth.account?.dateFormat;
});

const isLoading = computed(() => {
  if (!formatDate.value) {
    return true;
  }
  if (mappedData.value.length <= 0) {
    return true;
  }

  if (loadingTable.value) {
    return true;
  }
  return false;
});

function nextPage() {
  if (currentPage.value + 1 < totalPages.value) {
    currentPage.value++;
  }
}

function prevPage() {
  if (currentPage.value - 1 > 0) {
    currentPage.value--;
  }
}
const resultsPerPageOptions: Ref<number[]> = ref(ResultsPerPageOptions);

const pagesList: ComputedRef<number[]> = computed(() => {
  if (totalPages.value === 1) {
    return [1];
  } else {
    return range(1, totalPages.value + 1);
  }
});

function getTableRow(value: { [k in string]: unknown }): {
  value: unknown;
  type: ColumnType;
  metadata?: HeaderMetadata;
}[] {
  const valueTemp: {
    value: unknown;
    type: ColumnType;
    metadata?: HeaderMetadata;
  }[] = [];
  translatedHeaders.value.forEach((header) => {
    /**
     * here we can validate the column type for cast the value type for show content
     */
    const rowValue = () => {
      switch (header.columnType) {
        case "date":
          return DateHelper.of().format(
            value[header.key] as DateInput,
            formatDate.value
          );
        case "catalogStatus":
          return (value[header.key] as CatalogStatus).value;
        case "userTag":
          return value[header.key];
        case "action":
          return { options: header.options };
        default:
          return value[header.key];
      }
    };
    valueTemp.push({
      value: rowValue(),
      type: header.columnType,
      metadata: header.metadata,
    });
  });
  return valueTemp;
}

onMounted(() => {
  getTableData();
});
</script>

<style scoped lang="scss">
table {
  font-size: 0.8em;
  font-weight: bold;
}

.tableContainer {
  padding: 0;
}

.tableHeader {
  background-color: $GrayLight;
  color: $GrayPale;
  font-size: 1.2em;
  padding-left: 1em;
  text-align: start;
  border: none !important;
  border-top: 1px solid $GrayPale !important;
  border-bottom: 1px solid $GrayPale !important;
}

.tableLabel {
  font-weight: bold;
}

.tableRow {
  border-bottom: 1px solid $FourthColor;

  &:last-child {
    border: none;
  }
}

.numberResultsBadge {
  background-color: $GrayLight;
  color: $GraySolid;
  border: solid 1px $GrayPale;
}

.page-item {
  margin-left: 5px;
  > a {
    padding: 2px 9px 2px 9px;
    border-radius: 1em !important;
    background-color: $GrayLight;
    color: $GraySolid;
    &.active {
      background-color: $ActiveBlue;
      color: white;
    }
  }
}

.darkMode {
  table {
    font-size: 0.8em;
    font-weight: bold;
    color: #ffffff; // Letra blanca para mayor contraste
  }

  .tableContainer {
    padding: 0;
  }

  .tableHeader {
    background-color: $FourthColor; // Fondo oscuro
    color: #ffffff; // Letra blanca para mayor contraste
    font-size: 1.2em;
    padding-left: 1em;
    text-align: start;
    border: none !important;
    border-top: 1px solid $GrayPale !important; // Borde gris oscuro
    border-bottom: 1px solid $GrayPale !important; // Borde gris oscuro
  }

  .tableLabel {
    font-weight: bold;
    color: #ffffff; // Letra blanca para mayor contraste
  }

  .tableRow {
    border-bottom: 1px solid $GrayPale; // Borde gris oscuro

    &:last-child {
      border: none;
    }
  }

  .numberResultsBadge {
    background-color: $FourthColor; // Fondo oscuro
    color: #ffffff; // Letra blanca para mayor contraste
    border: solid 1px $GrayPale; // Borde gris oscuro
  }

  .page-item {
    margin-left: 5px;

    > a {
      padding: 2px 9px 2px 9px;
      border-radius: 1em !important;
      background-color: $GrayLight; // Fondo gris oscuro
      color: #ffffff; // Letra blanca para mayor contraste

      &.active {
        background-color: $ActiveBlue; // Fondo azul activo
        color: white;
      }
    }
  }
}
</style>
