import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "container-fluid tableParentContainer"
}
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-auto mt-3 mb-3" }
const _hoisted_4 = { class: "tableLabel" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col tableContainer" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = {
  key: 0,
  class: "m-0"
}
const _hoisted_9 = {
  key: 1,
  class: "m-0"
}
const _hoisted_10 = {
  key: 5,
  class: "m-0"
}
const _hoisted_11 = { class: "row justify-content-between mt-2" }
const _hoisted_12 = { class: "col-auto d-flex align-items-center" }
const _hoisted_13 = { class: "container-fluid" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-auto textPale" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "dropdown" }
const _hoisted_18 = {
  class: "badge rounded-pill numberResultsBadge",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_19 = { class: "dropdown-menu dropdown-menu-auto" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  class: "dropdown-item",
  href: "#",
  value: ""
}
const _hoisted_22 = { class: "col-auto" }
const _hoisted_23 = {
  "aria-label": "navigationTable",
  class: "p-2"
}
const _hoisted_24 = { class: "pagination m-0" }
const _hoisted_25 = ["onClick"]

import { useTablesServices } from "@/composables/useTablesServices";
import { DateHelper, DateInput } from "@/shared/dates/helpers/Date.helper";
import { CatalogStatus } from "@/shared/globals/badges/types/CatalogStatus.type";
import { StatusKeyType } from "@/shared/globals/badges/types/StatusesBadges.types";
import { formatNumberCurrency } from "@/shared/globals/helpers/Number.helper";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { headers } from "@/shared/globals/tables/consts/Headers.const";
import { ResultsPerPageOptions } from "@/shared/globals/tables/consts/ResultsPerPage.const";
import { HeaderMetadata } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { ColumnType } from "@/shared/globals/tables/types/HeaderTypes.type";
import { UserTag } from "@/shared/globals/tags/types/UserTag.type";
import { range } from "lodash";
import {
  ComputedRef,
  PropType,
  Ref,
  computed,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import DropdownMenu from "../UiTools/DropdownMenu.vue";
import IsLoading from "../UiTools/IsLoading.vue";
import StatusBadge from "../UiTools/StatusBadge.vue";
import ProfileTag from "../profile/ProfileTag.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralTable',
  props: {
  headersName: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  dataSource: {
    type: String,
    required: true,
  },
  orderBy: {
    type: String,
    default: "id",
  },
  orderType: {
    type: String as PropType<"asc" | "desc">,
    default: "desc",
  },
},
  setup(__props) {

const store = useStore();
const props = __props;

const {
  getTableData,
  currentPage,
  totalPages,
  pageSize,
  mappedData,
  translatedHeaders,
  loadingTable,
} = useTablesServices(
  props.dataSource,
  headers[props.headersName],
  props.orderBy,
  props.orderType
);

const formatDate = computed(() => {
  return store.state.auth.account?.dateFormat;
});

const isLoading = computed(() => {
  if (!formatDate.value) {
    return true;
  }
  if (mappedData.value.length <= 0) {
    return true;
  }

  if (loadingTable.value) {
    return true;
  }
  return false;
});

function nextPage() {
  if (currentPage.value + 1 < totalPages.value) {
    currentPage.value++;
  }
}

function prevPage() {
  if (currentPage.value - 1 > 0) {
    currentPage.value--;
  }
}
const resultsPerPageOptions: Ref<number[]> = ref(ResultsPerPageOptions);

const pagesList: ComputedRef<number[]> = computed(() => {
  if (totalPages.value === 1) {
    return [1];
  } else {
    return range(1, totalPages.value + 1);
  }
});

function getTableRow(value: { [k in string]: unknown }): {
  value: unknown;
  type: ColumnType;
  metadata?: HeaderMetadata;
}[] {
  const valueTemp: {
    value: unknown;
    type: ColumnType;
    metadata?: HeaderMetadata;
  }[] = [];
  translatedHeaders.value.forEach((header) => {
    /**
     * here we can validate the column type for cast the value type for show content
     */
    const rowValue = () => {
      switch (header.columnType) {
        case "date":
          return DateHelper.of().format(
            value[header.key] as DateInput,
            formatDate.value
          );
        case "catalogStatus":
          return (value[header.key] as CatalogStatus).value;
        case "userTag":
          return value[header.key];
        case "action":
          return { options: header.options };
        default:
          return value[header.key];
      }
    };
    valueTemp.push({
      value: rowValue(),
      type: header.columnType,
      metadata: header.metadata,
    });
  });
  return valueTemp;
}

onMounted(() => {
  getTableData();
});

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createBlock(IsLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (props.label)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(props.label), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("table", _hoisted_7, [
              _createElementVNode("colgroup", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header) => {
                  return (_openBlock(), _createElementBlock("col", {
                    span: "1",
                    style: _normalizeStyle(`width: ${header.width}`),
                    key: `tableCol${props.name}${header.key}`
                  }, null, 4))
                }), 128))
              ]),
              _createElementVNode("tbody", null, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(translatedHeaders), (header) => {
                    return (_openBlock(), _createElementBlock("th", {
                      class: "tableHeader pt-2 pb-2 border textPale",
                      style: _normalizeStyle(`width: ${header.width}`),
                      key: `tableHeader${props.name}${header.key}`
                    }, _toDisplayString(header.value), 5))
                  }), 128))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mappedData), (value, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: `tableRow${props.name}${index}`,
                    class: "tableRow"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getTableRow(value), (data, dataIndex) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: `tableCell${props.name}${index}-${dataIndex}-${data}`
                      }, [
                        (data.type === 'string')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(data.value), 1))
                          : (data.type === 'currency')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_unref(formatNumberCurrency)(data.value as number)), 1))
                            : (data.type === 'catalogStatus')
                              ? (_openBlock(), _createBlock(StatusBadge, {
                                  key: 2,
                                  status: (data.value as StatusKeyType)
                                }, null, 8, ["status"]))
                              : (data.type === 'userTag')
                                ? (_openBlock(), _createBlock(ProfileTag, {
                                    key: 3,
                                    userData: (data.value as UserTag),
                                    showEmail: data.metadata.showEmail
                                  }, null, 8, ["userData", "showEmail"]))
                                : (data.type === 'action')
                                  ? (_openBlock(), _createBlock(DropdownMenu, {
                                      key: 4,
                                      menuData: (data.value as Menu),
                                      name: `menu-${index}-${dataIndex}`
                                    }, null, 8, ["menuData", "name"]))
                                  : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(data.value), 1))
                      ]))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("global.tables.resultsPerPage")), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", _hoisted_18, [
                      _createTextVNode(_toDisplayString(_unref(pageSize)) + " ", 1),
                      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-caret-down" }, null, -1))
                    ]),
                    _createElementVNode("ul", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(resultsPerPageOptions.value, (resultsPerPageOption, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: `${props.name}-${index}-optionPaginator-${resultsPerPageOption}`,
                          onClick: ($event: any) => (pageSize.value = resultsPerPageOption)
                        }, [
                          _createElementVNode("a", _hoisted_21, _toDisplayString(resultsPerPageOption), 1)
                        ], 8, _hoisted_20))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("nav", _hoisted_23, [
              _createElementVNode("ul", _hoisted_24, [
                (_unref(currentPage) > _unref(totalPages))
                  ? (_openBlock(), _createElementBlock("li", {
                      key: 0,
                      class: "page-item",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (prevPage()))
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("a", {
                        class: "page-link",
                        href: "#",
                        "aria-label": "Previous"
                      }, [
                        _createElementVNode("span", { "aria-hidden": "true" }, "«")
                      ], -1)
                    ])))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pagesList.value, (page) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "page-item",
                    key: `${__props.name}-paginator-${page}`,
                    onClick: ($event: any) => (currentPage.value = page)
                  }, [
                    _createElementVNode("a", {
                      class: _normalizeClass(`page-link ${_unref(currentPage) == page ? 'active' : ''}`),
                      href: "#"
                    }, _toDisplayString(page), 3)
                  ], 8, _hoisted_25))
                }), 128)),
                (_unref(currentPage) < _unref(totalPages))
                  ? (_openBlock(), _createElementBlock("li", {
                      key: 1,
                      class: "page-item",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage()))
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("a", {
                        class: "page-link",
                        href: "#",
                        "aria-label": "Next"
                      }, [
                        _createElementVNode("span", { "aria-hidden": "true" }, "»")
                      ], -1)
                    ])))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]))
}
}

})