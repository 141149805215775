import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-3" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-auto mb-2" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "m-0 fs-09em" }
const _hoisted_7 = { class: "row justify-content-center" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "m-0 fs-07em textPale" }
const _hoisted_10 = { class: "row justify-content-center" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "container-fluid" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "textPale m-0" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "textPale m-0" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "container-fluid" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-12" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "textPale m-0" }

import { formatNumberCurrency } from "@/shared/globals/helpers/Number.helper";
import { UserTagExtended } from "@/shared/globals/tags/types/UserTag.type";
import { PropType, computed } from "vue";
import ProfilePicture from "../profile/ProfilePicture.vue";
import GeneralCard from "./GeneralCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserCard',
  props: {
  userData: {
    type: Object as PropType<UserTagExtended>,
  },
},
  setup(__props) {

const props = __props;

const name = computed(() => {
  return `${props.userData.firstName} ${props.userData.lastName}`;
});

console.log(props.userData);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GeneralCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ProfilePicture, {
              image: props.userData.profilePic,
              name: name.value,
              width: "2.5em"
            }, null, 8, ["image", "name"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(name.value), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(props.userData.position), 1)
          ])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row mt-2 justify-content-center actionsContainer" }, [
          _createElementVNode("div", { class: "col-auto pr-1" }, [
            _createElementVNode("button", { class: "btn p-0 bgPale" }, [
              _createElementVNode("i", { class: "bi bi-pen fs-07em" })
            ])
          ]),
          _createElementVNode("div", { class: "col-auto pl-1" }, [
            _createElementVNode("button", { class: "btn p-0 bgPale" }, [
              _createElementVNode("i", { class: "bi bi-trash fs-07em" })
            ])
          ])
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("p", _hoisted_15, _toDisplayString(props.userData.employeeId), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("global.employee")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(formatNumberCurrency)(props.userData.amount)), 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t("global.earnings")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})