import i18n from "@/shared/locales/i18n";
import { stringToRegExp } from "../../helpers/Regex.helper";
import { RuleInput } from "../types/RuleInput.type";

const t = i18n.global.t;

export function upperCaseLimited(quantity: number): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.upperCaseLimited", { quantity }),
    regExp: stringToRegExp(".*(\\S*[A-Z]){" + quantity + ",}", "gm"),
  };
}
export function lowerCaseLimited(quantity: number): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.lowerCaseLimited", { quantity }),
    regExp: stringToRegExp(".*(\\S*[a-z]){" + quantity + ",}", "gm"),
  };
}
export function especialCharactersLimited(quantity: number): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.especialCharactersLimited", {
      quantity,
    }),
    regExp: stringToRegExp(
      "(?:[^`!@#$%^&*\\-_=+'\\/.,]*[`!@#$%^&*\\-_=+'\\/.,]){" + quantity + "}",
      "gm"
    ),
  };
}
export function numberCharactersLimited(quantity: number): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.numberCharactersLimited", { quantity }),
    regExp: stringToRegExp(".*(\\S*[0-9]){" + quantity + ",}", "gm"),
  };
}

export function onlyNumberCharacters(): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.numberCharacters"),
    regExp: stringToRegExp("^[0-9]*$", "gm"),
  };
}

export function emailRule(): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.email"),
    regExp: /((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])/gm,
  };
}

export function minQuantity(quantity: number): RuleInput {
  return {
    messageI18n: t("global.inputs.rules.minQuantity", { quantity }),
    regExp: stringToRegExp(`.{${quantity}}`, "gm"),
  };
}
