export enum REQ_RES_CODES {
  ACCESS_EXPIRED = "ACCESS_EXPIRED",
  CREDENTIALS_UNKNOWN = "CREDENTIALS_UNKNOWN",
  INVALID_CODE = "INVALID_CODE",
  EXPIRED_CODE = "EXPIRED_CODE",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  ATTEMPTS_LIMIT_EXCEEDED = "ATTEMPTS_LIMIT_EXCEEDED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}
