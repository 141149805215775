import i18n from "../i18n";
import { LangType } from "../types/Lang.type";

export function setDefaultLang() {
  const storedLang = localStorage.getItem(
    `${window.location.hostname.split(".")[0]}DefaultLang`
  );
  if (storedLang) {
    i18n.global.locale.value = storedLang as LangType;
  } else {
    selectLang("es");
  }
}

export function selectLang(langKey: LangType) {
  localStorage.setItem(
    `${window.location.hostname.split(".")[0]}DefaultLang`,
    langKey
  );
  i18n.global.locale.value = langKey;
}

export function getLang(): LangType {
  return i18n.global.locale.value;
}
