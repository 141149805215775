import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-10" }
const _hoisted_4 = {
  class: "container",
  id: "formBox"
}
const _hoisted_5 = { class: "row justify-content-center mb-3" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "textBlack" }
const _hoisted_8 = { class: "row mb-4" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "row justify-content-between mb-4" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = {
  class: "actionableLabel",
  "data-bs-target": "#carouselAuthViews",
  "data-bs-slide-to": "1"
}
const _hoisted_15 = { class: "col-auto" }
const _hoisted_16 = {
  class: "actionableLabel",
  "data-bs-target": "#carouselAuthViews",
  "data-bs-slide-to": "2"
}
const _hoisted_17 = { class: "row mb-3" }
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = {
  class: "row",
  id: "anotherOptionsDivider"
}
const _hoisted_20 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_21 = { class: "m-0" }

import { useAuthServices } from "@/composables/useAuthServices";
import { emailRule } from "@/shared/globals/inputs/const/RuleInput.const";
import { LoginDto } from "@/store/auth/dtos/Login.dto";
import { computed, ref, Ref } from "vue";
import { useStore } from "vuex";
import InputMaster from "../globals/inputs/InputMaster.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginBox',
  setup(__props) {

const store = useStore();

const { login } = useAuthServices();

const accountId = computed(() => {
  return store.state.auth.account?.id;
});

const loginData: Ref<LoginDto> = ref<LoginDto>({
  password: "",
  email: "",
  account: { id: accountId.value },
});

function submitLogin(): void {
  login(loginData.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("login.title")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(InputMaster, {
                placeholder: "login.email",
                inputName: "loginEmail",
                modelValue: loginData.value.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((loginData.value.email) = $event)),
                rules: [_unref(emailRule)()]
              }, null, 8, ["modelValue", "rules"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(InputMaster, {
                placeholder: "login.password",
                inputName: "loginPassword",
                modelValue: loginData.value.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((loginData.value.password) = $event)),
                type: "password",
                iconInside: { name: 'eye', color: 'black' }
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("login.forgotPassword")), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("login.validateEmail")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                class: "btn w-100",
                id: "btnLogin",
                onClick: submitLogin
              }, _toDisplayString(_ctx.$t("login.submit")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col pr-1" }, [
              _createElementVNode("hr")
            ], -1)),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t("login.anotherAccessOptions")), 1)
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col pl-1" }, [
              _createElementVNode("hr")
            ], -1))
          ]),
          _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"row justify-content-around pt-2 pb-3\" id=\"anotherOption\" data-v-5c8de946><div class=\"col-auto\" data-v-5c8de946><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-5c8de946><img class=\"bg-transparent\" src=\"images/icon/brands/icon-apple.svg\" width=\"45px\" alt=\"appleLogo\" data-v-5c8de946></button></div><div class=\"col-auto\" data-v-5c8de946><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-5c8de946><img class=\"bg-transparent\" src=\"images/icon/brands/icon-microsoft.svg\" width=\"45px\" alt=\"microsoftLogo\" data-v-5c8de946></button></div><div class=\"col-auto\" data-v-5c8de946><button class=\"btn bg-transparent extraOptionButton p-0\" data-v-5c8de946><img class=\"bg-transparent\" src=\"images/icon/brands/icon-google.svg\" width=\"45px\" alt=\"googleLogo\" data-v-5c8de946></button></div></div>", 1))
        ])
      ])
    ])
  ]))
}
}

})