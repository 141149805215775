import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-fluid home h-100" }
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "container-fluid p-0" }
const _hoisted_5 = { class: "row justify-content-between" }
const _hoisted_6 = { class: "col-auto d-flex align-items-center" }
const _hoisted_7 = { class: "m-0 pl-2" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "container-fluid" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-auto p-0 displayedDataButtonContainer" }
const _hoisted_12 = { class: "col-auto p-0 displayedDataButtonContainer" }
const _hoisted_13 = { class: "col-auto addNewContainer" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "container-fluid" }
const _hoisted_19 = {
  key: 0,
  class: "row"
}
const _hoisted_20 = { class: "col-12 p-0" }
const _hoisted_21 = {
  key: 1,
  class: "row"
}

import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import UserCard from "@/components/globals/cards/UserCard.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { Table } from "@/shared/globals/tables/interfaces/Table.interface";
import { UserTagExtended } from "@/shared/globals/tags/types/UserTag.type";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'TalentManager',
  setup(__props) {

const { t } = useI18n();

const dataDisplayMode: Ref<"list" | "cards"> = ref("list");

const searchText: Ref<string> = ref("");

const table: Table = {
  headers: [
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.name"),
      key: "userData",
      columnType: "userTag",
      width: "380px",
      metadata: {
        showEmail: true,
      },
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.status"),
      key: "status",
      columnType: "catalogStatus",
      width: "150px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.accessDate"),
      key: "accessDate",
      columnType: "date",
      width: "190px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.position"),
      key: "position",
      columnType: "string",
      width: "150px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.actions"),
      columnType: "action",
      options: [
        {
          label: t(
            "home.talentManager.tables.talentManagerListUsers.actionList.delete"
          ),
          id: 0,
          action: () => {
            console.log("enable coming soon");
          },
          icon: "bi bi-trash3",
        },
        {
          label: t(
            "home.talentManager.tables.talentManagerListUsers.actionList.edit"
          ),
          id: 1,
          action: () => {
            console.log("disable coming soon");
          },
          icon: "bi bi-pencil",
        },
      ],
      width: "95px",
    },
  ],
  values: [
    {
      userData: {
        firstName: "Fabiano",
        lastName: "Gerald",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      position: "Chofer",
      employeeId: 13,
      accessDate: new Date(),
      amount: 756,
      status: { id: 1, value: "active" },
    },
    {
      userData: {
        firstName: "Forester",
        lastName: "Nicole",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938422",
      route: "ORY-HWS",
      position: "Asistente",
      employeeId: 5,
      accessDate: new Date(),
      amount: 7154,
      status: { id: 3, value: "pending" },
    },
    {
      userData: {
        firstName: "Agaro",
        lastName: "Fernando",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938423",
      route: "ORY-HWT",
      position: "Gerente",
      employeeId: 97,
      accessDate: new Date(),
      amount: 1643,
      status: { id: 1, value: "deny" },
    },
    {
      userData: {
        firstName: "julia",
        lastName: "Amber",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "OTF-AGS",
      position: "Operador",
      employeeId: 40,
      accessDate: new Date(),
      amount: 940,
      status: { id: 3, value: "new" },
    },
    {
      userData: {
        firstName: "Andres",
        lastName: "Camilo",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      employeeId: 100,
      position: "chofer",
      accessDate: new Date(),
      amount: 76,
      status: { id: 1, value: "deny" },
    },
    {
      userData: {
        firstName: "Jhon",
        lastName: "Cano",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      position: "chofer",
      employeeId: 48,
      accessDate: new Date(),
      amount: 1006,
      status: { id: 1, value: "active" },
    },
    {
      userData: {
        firstName: "David",
        lastName: "Martinez",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      employeeId: 25,
      route: "ORY-HWI",
      position: "chofer",
      accessDate: new Date(),
      amount: 356,
      status: { id: 3, value: "new" },
    },
  ],
};

const userData: UserTagExtended[] = (table.values as any[]).map((user) => {
  return {
    firstName: user.userData.firstName,
    lastName: user.userData.lastName,
    employeeId: user.employeeId,
    position: user.position,
    amount: user.amount,
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(GeneralCard, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("home.talentManager.requests")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: _normalizeClass(`btn leftButton ${
                            dataDisplayMode.value === 'list' ? 'activeButton' : ''
                          }`),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (dataDisplayMode.value = 'list'))
                        }, _cache[4] || (_cache[4] = [
                          _createElementVNode("i", { class: "bi bi-list-task" }, null, -1)
                        ]), 2)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: _normalizeClass(`btn rightButton ${
                            dataDisplayMode.value === 'cards' ? 'activeButton' : ''
                          }`),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (dataDisplayMode.value = 'cards'))
                        }, _cache[5] || (_cache[5] = [
                          _createElementVNode("i", { class: "bi bi-columns-gap" }, null, -1)
                        ]), 2)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("button", {
                          class: "btn",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (dataDisplayMode.value = 'cards'))
                        }, _toDisplayString(_ctx.$t("home.talentManager.addNew")), 1)
                      ])
                    ])
                  ])
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(BasicInput, {
                    name: "searchTalent",
                    placeholder: _ctx.$t('global.search'),
                    modelValue: searchText.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchText).value = $event))
                  }, null, 8, ["placeholder", "modelValue"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          (dataDisplayMode.value === 'list')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(GeneralCard, { class: "mb-3" }, {
                    content: _withCtx(() => [
                      _createVNode(GeneralTable, {
                        dataSource: "/users/",
                        headersName: "DASHBOARD_HEADERS",
                        name: "talentManagerListUsersTable",
                        label: 
                      _ctx.$t(
                        'home.talentManager.tables.talentManagerListUsers.tableLabel'
                      )
                    
                      }, null, 8, ["label"])
                    ]),
                    _: 1
                  })
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userData), (user, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-3 mt-2",
                    key: 'userCard' + index
                  }, [
                    _createVNode(UserCard, { userData: user }, null, 8, ["userData"])
                  ]))
                }), 128))
              ]))
        ])
      ])
    ])
  ]))
}
}

})