import { TableHeader } from "../interfaces/TableHeader.interface";

export const DashboardHeaders: TableHeader[] = [
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.name",
    key: "userData",
    mappedKey: "employee.firstName",
    columnType: "userTag",
    width: "380px",
    metadata: {
      showEmail: false,
    },
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.id",
    key: "id",
    mappedKey: "id",
    columnType: "string",
    width: "100px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.department",
    key: "department",
    mappedKey: "employee.department.name",
    columnType: "string",
    width: "150px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.position",
    key: "position",
    mappedKey: "employee.position.name",
    columnType: "string",
    width: "150px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.requestDate",
    key: "requestDate",
    mappedKey: "registrationDate",
    columnType: "date",
    width: "190px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.amount",
    key: "amount",
    mappedKey: "employee.position.baseSalary",
    columnType: "currency",
    width: "120px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.status",
    key: "status",
    mappedKey: "status",
    columnType: "catalogStatus",
    width: "150px",
  },
  {
    sortable: true,
    value: "home.dashboard.tables.newTalent.actions",
    columnType: "action",
    options: [
      {
        label: "home.dashboard.tables.newTalent.actionList.enable",
        id: 0,
        action: () => {
          console.log("enable coming soon");
        },
      },
      {
        label: "home.dashboard.tables.newTalent.actionList.disable",
        id: 1,
        action: () => {
          console.log("disable coming soon");
        },
      },
      {
        label: "home.dashboard.tables.newTalent.actionList.setPending",
        id: 2,
        action: () => {
          console.log("setPending coming soon");
        },
      },
    ],
    width: "95px",
  },
];
