<template>
  <div class="container-fluid home h-100">
    <div class="row mb-3">
      <div class="col-12">
        <GeneralCard>
          <template v-slot:content>
            <div class="container-fluid p-0">
              <div class="row justify-content-between">
                <div class="col-auto d-flex align-items-center">
                  <h5 class="m-0 pl-2">
                    {{ $t("home.talentManager.requests") }}
                  </h5>
                </div>

                <div class="col-auto">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-auto p-0 displayedDataButtonContainer">
                        <button
                          :class="`btn leftButton ${
                            dataDisplayMode === 'list' ? 'activeButton' : ''
                          }`"
                          @click="dataDisplayMode = 'list'"
                        >
                          <i class="bi bi-list-task"></i>
                        </button>
                      </div>

                      <div class="col-auto p-0 displayedDataButtonContainer">
                        <button
                          :class="`btn rightButton ${
                            dataDisplayMode === 'cards' ? 'activeButton' : ''
                          }`"
                          @click="dataDisplayMode = 'cards'"
                        >
                          <i class="bi bi-columns-gap"></i>
                        </button>
                      </div>

                      <div class="col-auto addNewContainer">
                        <button class="btn" @click="dataDisplayMode = 'cards'">
                          {{ $t("home.talentManager.addNew") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12">
                  <BasicInput
                    name="searchTalent"
                    :placeholder="$t('global.search')"
                    v-model="searchText"
                  ></BasicInput>
                </div>
              </div>
            </div>
          </template>
        </GeneralCard>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row" v-if="dataDisplayMode === 'list'">
            <div class="col-12 p-0">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <GeneralTable
                    dataSource="/users/"
                    headersName="DASHBOARD_HEADERS"
                    name="talentManagerListUsersTable"
                    :label="
                      $t(
                        'home.talentManager.tables.talentManagerListUsers.tableLabel'
                      )
                    "
                  ></GeneralTable>
                </template>
              </GeneralCard>
            </div>
          </div>
          <div class="row" v-else>
            <div
              class="col-3 mt-2"
              v-for="(user, index) in userData"
              :key="'userCard' + index"
            >
              <UserCard :userData="user" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import UserCard from "@/components/globals/cards/UserCard.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { Table } from "@/shared/globals/tables/interfaces/Table.interface";
import { UserTagExtended } from "@/shared/globals/tags/types/UserTag.type";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const dataDisplayMode: Ref<"list" | "cards"> = ref("list");

const searchText: Ref<string> = ref("");

const table: Table = {
  headers: [
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.name"),
      key: "userData",
      columnType: "userTag",
      width: "380px",
      metadata: {
        showEmail: true,
      },
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.status"),
      key: "status",
      columnType: "catalogStatus",
      width: "150px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.accessDate"),
      key: "accessDate",
      columnType: "date",
      width: "190px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.position"),
      key: "position",
      columnType: "string",
      width: "150px",
    },
    {
      sortable: true,
      value: t("home.talentManager.tables.talentManagerListUsers.actions"),
      columnType: "action",
      options: [
        {
          label: t(
            "home.talentManager.tables.talentManagerListUsers.actionList.delete"
          ),
          id: 0,
          action: () => {
            console.log("enable coming soon");
          },
          icon: "bi bi-trash3",
        },
        {
          label: t(
            "home.talentManager.tables.talentManagerListUsers.actionList.edit"
          ),
          id: 1,
          action: () => {
            console.log("disable coming soon");
          },
          icon: "bi bi-pencil",
        },
      ],
      width: "95px",
    },
  ],
  values: [
    {
      userData: {
        firstName: "Fabiano",
        lastName: "Gerald",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      position: "Chofer",
      employeeId: 13,
      accessDate: new Date(),
      amount: 756,
      status: { id: 1, value: "active" },
    },
    {
      userData: {
        firstName: "Forester",
        lastName: "Nicole",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938422",
      route: "ORY-HWS",
      position: "Asistente",
      employeeId: 5,
      accessDate: new Date(),
      amount: 7154,
      status: { id: 3, value: "pending" },
    },
    {
      userData: {
        firstName: "Agaro",
        lastName: "Fernando",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938423",
      route: "ORY-HWT",
      position: "Gerente",
      employeeId: 97,
      accessDate: new Date(),
      amount: 1643,
      status: { id: 1, value: "deny" },
    },
    {
      userData: {
        firstName: "julia",
        lastName: "Amber",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "OTF-AGS",
      position: "Operador",
      employeeId: 40,
      accessDate: new Date(),
      amount: 940,
      status: { id: 3, value: "new" },
    },
    {
      userData: {
        firstName: "Andres",
        lastName: "Camilo",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      employeeId: 100,
      position: "chofer",
      accessDate: new Date(),
      amount: 76,
      status: { id: 1, value: "deny" },
    },
    {
      userData: {
        firstName: "Jhon",
        lastName: "Cano",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      route: "ORY-HWI",
      position: "chofer",
      employeeId: 48,
      accessDate: new Date(),
      amount: 1006,
      status: { id: 1, value: "active" },
    },
    {
      userData: {
        firstName: "David",
        lastName: "Martinez",
        email: "emailtest@smarttalent.com",
      },
      id: "VT938421",
      employeeId: 25,
      route: "ORY-HWI",
      position: "chofer",
      accessDate: new Date(),
      amount: 356,
      status: { id: 3, value: "new" },
    },
  ],
};

const userData: UserTagExtended[] = (table.values as any[]).map((user) => {
  return {
    firstName: user.userData.firstName,
    lastName: user.userData.lastName,
    employeeId: user.employeeId,
    position: user.position,
    amount: user.amount,
  };
});
</script>

<style lang="scss" scoped>
.addNewContainer {
  button {
    background-color: #00e097;
    color: #fff;
  }
}

.displayedDataButtonContainer:hover {
  button {
    background-color: $ActiveBlue;
    color: #fff;
  }
}
</style>
