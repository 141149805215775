<template>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <span
      class="badge rounded-pill"
      :style="{
        backgroundColor: statusBadgeData.bgColor,
        color: statusBadgeData.color,
      }"
      >{{ statusBadgeData.label }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { StatusesBadges } from "@/shared/globals/badges/consts/StatusesBadges.const";
import { StatusKeyType } from "@/shared/globals/badges/types/StatusesBadges.types";
import { computed, defineProps, PropType, ref } from "vue";

const props = defineProps({
  status: { type: Object as PropType<StatusKeyType>, required: true },
});

const statusesBadges = ref(StatusesBadges());

const statusBadgeData = computed(() => {
  return statusesBadges.value.find((status) => status.key === props.status);
});
</script>

<style lang="scss" scoped></style>
