import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 w-100 d-flex justify-content-center align-items-center" }

import { StatusesBadges } from "@/shared/globals/badges/consts/StatusesBadges.const";
import { StatusKeyType } from "@/shared/globals/badges/types/StatusesBadges.types";
import { computed, PropType, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBadge',
  props: {
  status: { type: Object as PropType<StatusKeyType>, required: true },
},
  setup(__props) {

const props = __props;

const statusesBadges = ref(StatusesBadges());

const statusBadgeData = computed(() => {
  return statusesBadges.value.find((status) => status.key === props.status);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "badge rounded-pill",
      style: _normalizeStyle({
        backgroundColor: statusBadgeData.value.bgColor,
        color: statusBadgeData.value.color,
      })
    }, _toDisplayString(statusBadgeData.value.label), 5)
  ]))
}
}

})