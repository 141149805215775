<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.title") }}</h2>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12">
              <InputMaster
                placeholder="login.email"
                inputName="loginEmail"
                v-model="loginData.email"
                :rules="[emailRule()]"
              ></InputMaster>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <InputMaster
                placeholder="login.password"
                inputName="loginPassword"
                v-model="loginData.password"
                type="password"
                :iconInside="{ name: 'eye', color: 'black' }"
              ></InputMaster>
            </div>
          </div>
          <div class="row justify-content-between mb-4">
            <div class="col-auto">
              <span
                class="actionableLabel"
                data-bs-target="#carouselAuthViews"
                data-bs-slide-to="1"
                >{{ $t("login.forgotPassword") }}</span
              >
            </div>
            <div class="col-auto">
              <span
                class="actionableLabel"
                data-bs-target="#carouselAuthViews"
                data-bs-slide-to="2"
                >{{ $t("login.validateEmail") }}</span
              >
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <button class="btn w-100" id="btnLogin" @click="submitLogin">
                {{ $t("login.submit") }}
              </button>
            </div>
          </div>
          <div class="row" id="anotherOptionsDivider">
            <div class="col pr-1"><hr /></div>
            <div class="col-auto p-0 dividerText d-flex align-items-center">
              <p class="m-0">{{ $t("login.anotherAccessOptions") }}</p>
            </div>
            <div class="col pl-1"><hr /></div>
          </div>
          <div class="row justify-content-around pt-2 pb-3" id="anotherOption">
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-apple.svg"
                  width="45px"
                  alt="appleLogo"
                />
              </button>
            </div>
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-microsoft.svg"
                  width="45px"
                  alt="microsoftLogo"
                />
              </button>
            </div>
            <div class="col-auto">
              <button class="btn bg-transparent extraOptionButton p-0">
                <img
                  class="bg-transparent"
                  src="images/icon/brands/icon-google.svg"
                  width="45px"
                  alt="googleLogo"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import { emailRule } from "@/shared/globals/inputs/const/RuleInput.const";
import { LoginDto } from "@/store/auth/dtos/Login.dto";
import { computed, ref, Ref } from "vue";
import { useStore } from "vuex";
import InputMaster from "../globals/inputs/InputMaster.vue";

const store = useStore();

const { login } = useAuthServices();

const accountId = computed(() => {
  return store.state.auth.account?.id;
});

const loginData: Ref<LoginDto> = ref<LoginDto>({
  password: "",
  email: "",
  account: { id: accountId.value },
});

function submitLogin(): void {
  login(loginData.value);
}
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnLogin {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}

#anotherOptionsDivider {
  hr {
    color: rgb(71, 71, 71);
  }
  font-size: 0.6em;
  color: rgb(0, 0, 0);
}

#anotherOption {
  button {
    &:hover {
      background-color: $FourthColor;
    }
  }
}

.extraOptionButton {
  &:hover {
    background-color: rgb(206, 206, 206) !important;
  }
}
</style>
