import { mapFromHeader } from "@/shared/globals/tables/helpers/tables.helper";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { GetTableData } from "@/shared/globals/tables/services/Tables.services";
import { computed, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastService";

export function useTablesServices(
  urlData: string,
  headers: TableHeader[],
  orderBy = "id",
  orderType: "asc" | "desc" = "desc"
) {
  const currentData = ref([]);
  const { t } = useI18n();
  const currentPage = ref(1);
  const pageSize = ref(5);
  const loadingTable = ref(false);

  const totalPages = ref(0);
  const totalItems = ref(0);
  const translatedHeaders: Ref<TableHeader[]> = ref(
    headers.map((header) => {
      const newHeader = { ...header, value: t(header.value) };
      if (newHeader.options) {
        newHeader.options = newHeader.options.map((option) => {
          return { ...option, label: t(option.label) };
        });
      }
      return newHeader;
    })
  );

  watch(currentPage, () => {
    getTableData();
  });

  watch(pageSize, () => {
    getTableData();
  });

  const mappedData = computed(() => {
    return mapFromHeader(headers, currentData.value);
  });

  function getTableData() {
    loadingTable.value = true;
    GetTableData(urlData, {
      page: currentPage.value,
      size: pageSize.value,
      orderBy,
      orderType,
    })
      .then(({ data }) => {
        currentData.value = data.elements;
        loadingTable.value = false;
        totalPages.value = data.metadata.totalPages;
        totalItems.value = data.metadata.totalItems;
      })
      .catch(() => {
        useToast().errorToast(t("global.unknownError"));
      });
  }

  return {
    getTableData,
    currentData,
    translatedHeaders,
    currentPage,
    pageSize,
    mappedData,
    totalPages,
    totalItems,
    loadingTable,
  };
}
