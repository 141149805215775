import i18n from "@/shared/locales/i18n";
import {
  Catalog,
  TranslatedCatalog,
} from "@/store/catalogs/interfaces/Catalogs.interface";
export function getCatalogValue(catalog: Catalog) {
  const lang = i18n.global.locale;
  return catalog.catalogMetadata.name
    ? catalog.name
    : (catalog.catalogMetadata as unknown as TranslatedCatalog).name.language[
        lang.value
      ];
}
