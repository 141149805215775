<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="container" id="formBox">
          <div class="row justify-content-center mb-3">
            <div class="col-auto d-flex align-items-center">
              <h2 class="textBlack">{{ $t("login.forgotPassTitle") }}</h2>
            </div>
          </div>
          <div class="row justify-content-between mb-4">
            <div class="col-auto">
              <i class="bi bi-arrow-left-circle actionableLabel mr-1"></i>
              <span class="actionableLabel" @click="goToLogin()">{{
                $t("login.name")
              }}</span>
            </div>

            <div class="col-auto">
              <span
                class="actionableLabel"
                @click="forgotPassStep = 'confirm'"
                >{{ $t("login.haveACodeYet") }}</span
              >
            </div>
          </div>
          <div v-if="forgotPassStep === 'request'" class="row mb-4">
            <div class="col-12">
              <InputMaster
                placeholder="login.email"
                inputName="forgotPassEmail"
                v-model="forgotPassData.email"
                :rules="[emailRule()]"
              ></InputMaster>
            </div>
          </div>
          <div v-if="forgotPassStep === 'confirm'" class="row mb-4">
            <div class="col-12">
              <InputMaster
                placeholder="login.email"
                inputName="forgotPassConfirmEmail"
                v-model="confirmForgotPassData.email"
                :rules="[emailRule()]"
              ></InputMaster>
            </div>
          </div>
          <div v-if="forgotPassStep === 'confirm'" class="row mb-4">
            <div class="col-12">
              <InputMaster
                placeholder="login.newPassword"
                inputName="forgotPassNewPass"
                v-model="confirmForgotPassData.newPassword"
                :rules="[
                  upperCaseLimited(2),
                  lowerCaseLimited(2),
                  especialCharactersLimited(1),
                  numberCharactersLimited(2),
                ]"
                type="password"
              ></InputMaster>
            </div>
          </div>
          <div v-if="forgotPassStep === 'confirm'" class="row mb-4">
            <div class="col-12">
              <InputMaster
                placeholder="login.confirmationCode"
                inputName="forgotPassConfirmationCode"
                v-model="confirmForgotPassData.confirmationCode"
                :rules="[onlyNumberCharacters(), minQuantity(3)]"
              ></InputMaster>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <button
                class="btn w-100"
                id="btnLogin"
                @click="submitForgotPassword"
              >
                {{ $t("login.viewsSubmit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthServices } from "@/composables/useAuthServices";
import {
  emailRule,
  especialCharactersLimited,
  lowerCaseLimited,
  minQuantity,
  numberCharactersLimited,
  onlyNumberCharacters,
  upperCaseLimited,
} from "@/shared/globals/inputs/const/RuleInput.const";
import { ConfirmForgotPasswordDto } from "@/store/auth/dtos/ConfirmForgotPassword.dto";
import { ForgotPasswordDto } from "@/store/auth/dtos/ForgotPassword.dto";
import { defineEmits, ref, watch } from "vue";
import InputMaster from "../globals/inputs/InputMaster.vue";

const { forgotPassword, confirmForgotPassword, forgotPassStep } =
  useAuthServices();

const emit = defineEmits(["goToLogin"]);

const forgotPassData = ref<ForgotPasswordDto>({ email: "" });
const confirmForgotPassData = ref<ConfirmForgotPasswordDto>({
  email: "",
  confirmationCode: "",
  newPassword: "",
});

function submitForgotPassword(): void {
  if (forgotPassStep.value === "request") {
    forgotPassword(forgotPassData.value);
  } else {
    confirmForgotPassword(confirmForgotPassData.value, goToLogin);
  }
}

function goToLogin(): void {
  emit("goToLogin");
}

watch(forgotPassStep, () => {
  confirmForgotPassData.value = {
    email: forgotPassData.value.email,
    confirmationCode: "",
    newPassword: "",
  };
  forgotPassData.value = { email: "" };
});
</script>

<style lang="scss" scoped>
.input-group {
  $labelWith: 35%;
  $inputWith: calc(100% - $labelWith);
  span {
    width: $labelWith;
    text-align: start;
  }
  input {
    width: $inputWith;
  }
}

#btnLogin {
  background-color: $ThirdColor;
  border-color: $ThirdColor;
  color: white;
  &:hover {
    color: $ThirdColor;
    background-color: white;
  }
}
</style>
