<template>
  <div class="container-fluid home h-100">
    <div class="row mb-3">
      <div class="col-3 d-flex align-items-center">
        <div class="container-fluid">
          <div class="row justify-content-start">
            <div class="col-auto p-0">
              <h4>
                {{
                  $t("home.dashboard.welcome", {
                    userName: user?.employee.firstName,
                  })
                }}
              </h4>
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="col-auto p-0">
              <p class="m-0 littleFont boldFont">
                {{ $t("home.dashboard.welcomeMessage") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <card-label
          top-label="home.dashboard.employees"
          name="employeesLabelCard"
          :value="{ value: 1200, type: 'number' }"
          :icon="{ icon: 'bi bi-card-checklist' }"
        ></card-label>
      </div>
      <div class="col-3">
        <card-label
          top-label="home.dashboard.employees"
          name="totalRevLabelCard"
          :value="{ value: 824523.0, type: 'currency' }"
          :icon="{ icon: 'bi bi-cash-coin' }"
        ></card-label>
      </div>
      <div class="col-3">
        <card-label
          top-label="home.dashboard.employees"
          name="totalExpensesLabelCard"
          :value="{ value: 73635.03, type: 'currency' }"
          :icon="{ icon: 'bi bi-receipt-cutoff' }"
        ></card-label>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 p-0">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <div class="w-100 h-100 container-fluid">
                    <div class="row justify-content-end">
                      <div class="col-4">
                        <BasicInput
                          name="searchHeader"
                          :placeholder="$t('global.search')"
                        ></BasicInput>
                      </div>
                      <div class="col-auto">
                        <RoundedIcon
                          fontSize="1.2em"
                          width="2.1em"
                          icon="bi-sliders2"
                          :bordered="true"
                        ></RoundedIcon>
                      </div>
                    </div>
                    <div class="row justify-content-end pt-2 pb-2">
                      <div class="col-auto">
                        <p class="m-0 textPale">
                          {{ $t("home.dashboard.viewAll") }}
                        </p>
                      </div>
                    </div>
                    <div class="row" :style="{ height: '18em' }"></div>
                  </div>
                </template>
              </GeneralCard>
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <GeneralTable
                    dataSource="/users/"
                    headersName="DASHBOARD_HEADERS"
                    name="newTalentTable"
                    :label="$t('home.dashboard.tables.newTalent.tableLabel')"
                  ></GeneralTable>
                </template>
              </GeneralCard>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <div class="w-100 h-100"><GlobalChart :chart="2" /></div>
                </template>
              </GeneralCard>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <div class="w-100 h-100"><GlobalChart :chart="0" /></div>
                </template>
              </GeneralCard>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <GeneralCard class="mb-3">
                <template v-slot:content>
                  <div class="w-100 h-100"><GlobalChart :chart="1" /></div>
                </template>
              </GeneralCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CardLabel from "@/components/globals/cards/CardLabel.vue";
import GeneralCard from "@/components/globals/cards/GeneralCard.vue";
import GlobalChart from "@/components/globals/charts/GlobalChart.vue";
import RoundedIcon from "@/components/globals/icons/RoundedIcon.vue";
import BasicInput from "@/components/globals/inputs/BasicInput.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { useUsersServices } from "@/composables/useUsersServices";

const { user } = useUsersServices();
</script>

<style lang="scss" scoped>
.legends {
  padding-top: 20px;
  overflow: hidden;
}

.legend {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 50%;
}

.legend--this {
  background-color: #5555ff;
}

.legend--prev {
  background-color: #ff55b8;
}

.axis {
  position: absolute;
  color: #fff;
  z-index: 1;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  bottom: 0;
}

.axis .tick {
  flex: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.2;
  font-size: 11px;
  text-align: center;
  line-height: 40px;
  padding-top: 150px;
}

.axis .tick:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

.axis .tick .value {
  transform: translateY(-40px);
  transition: 0.3s transform;
  position: absolute;
  top: 20px;
  color: #444;
  border-radius: 2px;
  width: 100%;
  line-height: 20px;
}

.axis .tick:hover .value.value--this {
  transform: translateY(0);
  display: block;
}

.value.value--this {
  color: #5555ff;
  font-weight: bold;
}
</style>
