import _ from "lodash";
import { getCatalogValue } from "../../helpers/Catalogs.helper";
import { TableHeader } from "../interfaces/TableHeader.interface";
import { Row } from "../types/Row.type";

export function mapFromHeader(headers: TableHeader[], data: any[]): Row[] {
  return data.map((tableRow: Row) => {
    const row: Row = {};
    headers.forEach((header) => {
      switch (header.columnType) {
        case "userTag":
          row[header.key] = {
            firstName: _.get(tableRow, header.mappedKey),
            lastName: _.get(
              tableRow,
              header.mappedKey.replace("firstName", "lastName"),
              ""
            ),
          };
          break;
        case "currency":
          row[header.key] = +_.get(tableRow, header.mappedKey, 0);
          break;

        case "catalog":
          row[header.key] = {
            id: _.get(tableRow, header.mappedKey, 0),
            value: getCatalogValue(_.get(tableRow, header.mappedKey, 0)),
          };
          break;
        case "catalogStatus":
          row[header.key] = {
            id: _.get(tableRow, header.mappedKey, 0),
            value: getCatalogValue(_.get(tableRow, header.mappedKey, 0)),
          };
          break;
        case "date":
          row[header.key] = new Date(_.get(tableRow, header.mappedKey));
          break;
        default:
          row[header.key] = _.get(tableRow, header.mappedKey, "-");
          break;
      }
    });
    return row;
  });
}
